import { memo } from 'react'
import { Table } from '@nbit/arco'
import { ThemeEnum } from '@nbit/chart-utils'
import { useCommonStore } from '@/store/common'
import NoDataImage from '@/components/no-data-image'
import { Type } from '@/components/lazy-image'
import classNames from 'classnames'
import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import styles from './index.module.css'

/** 数据长度 */
const numVal = 0
function CommonTable(props) {
  const { theme } = useCommonStore()

  /**
   * 获取其他主题下的 className,2z 主题下默认不需要处理了
   */
  const getClassName = () => {
    return {
      [InitThemeColor['2z']]: '',
      [InitThemeColor.ok]: 'common-table-ok',
    }[systemThemeColor]
  }

  return (
    <div className={`${theme === ThemeEnum.dark ? 'dark-spin' : 'light-spin'}`}>
      <Table
        border={false}
        pagination={false}
        className={classNames(styles['common-table-root'], getClassName())}
        hover={false}
        {...props}
        noDataElement={<NoDataImage name="image_default_graph" />}
      />
    </div>
  )
}

export default memo(CommonTable)
