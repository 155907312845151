/**
 * 转换记录 - 表格数据定义
 */
import { TableColumnProps } from '@nbit/arco'
import { t } from '@lingui/macro'
import { formatDate } from '@/helper/date'
import { formatCurrency } from '@nbit/utils/lib/decimal'
import { useCommonStore } from '@/store/common'
import { profitLossFn, colorFn } from './recording-tool'
import style from './index.module.css'

function TableData(dataList) {
  const { colors } = useCommonStore()

  /**
   * 数据字典过滤字段值查询状态
   */
  const dataListMethod = clearingStatus => {
    let val
    dataList.forEach(element => {
      if (element?.codeVal === clearingStatus) {
        val = (
          <div className={style.tabel}>
            <div className={colorFn(element?.codeVal)}>{element?.codeKey || '--'}</div>
          </div>
        )
      }
    })
    return val
  }

  let data: TableColumnProps[] = [
    {
      title: t`features_c2c_trade_history_records_content_table_column_gkswfpwsjnnnigaff1dwq`,
      dataIndex: 'createdByTime',
      render: (_, row) => {
        return row?.createdByTime ? formatDate(row?.createdByTime) : '--'
      },
    },
    {
      title: t`features_orders_order_detail_5101068`,
      dataIndex: 'orderNo',
      width: 220,
      render: (_, row) => {
        return row?.orderNo ? row?.orderNo : '--'
      },
    },
    {
      title: t`features_ternary_option_trade_form_guide_gmn6je1zlm`,
      dataIndex: 'gamePlay',
      render: (_, row) => {
        return row?.gamePlay ? row?.gamePlay : '--'
      },
    },
    {
      title: t`features_entertainment_area_gamerecording_table_data_ilhfy8tf7t`,
      sorter: (a, b) => a.amount - b.amount,
      dataIndex: 'amount',
      render: (_, row) => {
        return row?.amount ? `${formatCurrency(row?.amount)} ${row?.coin || ''}` : 0
      },
    },
    {
      title: t`features_entertainment_area_gamerecording_table_data_yoj_3032mb`,
      dataIndex: 'realAmount',
      render: (_, row) => {
        return row?.realAmount ? `${formatCurrency(row?.realAmount)} ${row?.coin || ''}` : 0
      },
    },
    {
      title: t`features_ternary_option_historical_component_historical_table_index_oop5lshpfi`,
      dataIndex: 'profitLoss',
      render: (_, row) => {
        return (
          <div className={style.tabel}>
            <div className={profitLossFn(row?.profitLoss, colors)}>
              {row?.profitLoss ? `${formatCurrency(row?.profitLoss)} ${row?.coin || ''}` : '0'}
            </div>
          </div>
        )
      },
    },
    {
      title: t`features_agent_agency_center_revenue_details_index_5101520`,
      dataIndex: 'settDtt',
      render: (_, row) => {
        return row?.settDtt ? formatDate(row?.settDtt) : '--'
      },
    },
    {
      title: t`features_entertainment_area_gamerecording_table_data_mcqq9rnlyy`,
      dataIndex: 'clearingStatus',
      render: (_, row) => {
        return row?.clearingStatus ? dataListMethod(row?.clearingStatus) : '--'
      },
    },
  ]
  return data
}

export default TableData
