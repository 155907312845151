import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { t } from '@lingui/macro'
import { oss_area_code_image_domain_address, oss_ra_image_domain_address } from '@/constants/oss'
import CommonTable from '@/features/recreation/component/common-table'
import RecordTabs from '@/features/recreation/theme/components/record-tabs'
import { Image, TableColumnProps, Tooltip, Skeleton, Spin, Avatar } from '@nbit/arco'
import { getRaHallGameRecord } from '@/apis/entertainment-area'
import { CodeType } from '@/constants/entertainment-area'
import { useRequest } from 'ahooks'
import { getCodeDetailList } from '@/apis/common'
import {
  fromDefault,
  colorFn,
  profitLossNegative,
  profitLossFn,
} from '@/features/entertainment-area/game-records/recording-tool'
import { useUserStore } from '@/store/user'
import { useCommonStore } from '@/store/common'
import { IAreaUserGameRecordType } from '@/typings/api/entertainment-area'
import { formatDate } from '@/helper/date'
import { formatCurrency } from '@nbit/utils/lib/decimal'
import TableData from '@/features/entertainment-area/game-records/table-data'
import classNames from 'classnames'
import LazyImage from '@/components/lazy-image'
import useApiAllCoinSymbolInfo from '@/hooks/features/market/common/use-api-all-coin-symbol-info'
import { get, isEmpty } from 'lodash'
import { usePersonalCenterStore } from '@/store/user/personal-center'
import { RankingTableType } from '@/typings/common'
import { RankingListTableTypeEnum, getRankingListTableTypeName } from '@/constants/locale-config'
import styles from './index.module.css'

const DEFAULT_COIN = 'USDT'

type TabsList = {
  id: string
  name: string
}

interface ICurrencyImg {
  [key: string]: string
}

/** 骨架默认数据 */
const skeletonNum = 10

export default function RankingListTable({ hiddenOwn, className }: { hiddenOwn?: boolean; className?: string }) {
  let isBillboard = RankingListTableTypeEnum.billboard
  const [tableId, setTableId] = useState<string>('')
  const [data, setData] = useState<IAreaUserGameRecordType[]>([])
  const [currentColumns, setCurrentColumns] = useState<TableColumnProps[]>([])
  const [dataList, setDataList] = useState<CodeType[]>([]) // 结算状态数据
  const [tabsList, setTabsList] = useState<TabsList[] | RankingTableType[]>([])

  const [myBetData, setMyBetData] = useState<IAreaUserGameRecordType[]>([])
  const [myBetColumns, setMyBetColumns] = useState<TableColumnProps[]>([])
  const { locale, localeInfo } = useCommonStore()
  const { isLogin, personalCenterSettings } = useUserStore()
  const [info, setinfo] = useState(true)
  const [currencyImg, setCurrencyImg] = useState<ICurrencyImg>()
  const symbolInfo = useApiAllCoinSymbolInfo()
  const { fiatCurrencyData } = usePersonalCenterStore()

  useEffect(() => {
    if (isEmpty(fiatCurrencyData?.currencyList)) return
    let result = {}
    fiatCurrencyData?.currencyList.forEach(i => {
      if (i.currencyEnName) {
        result[i.currencyEnName] = i.countryFlagImg
      }
    })
    setCurrencyImg(result)
  }, [fiatCurrencyData])

  /**
   * 数据字典过滤字段值查询状态
   */
  const dataListMethod = clearingStatus => {
    let val
    dataList.forEach(element => {
      if (element?.codeVal === clearingStatus) {
        val = (
          <div className={styles.tabel}>
            <div className={colorFn(element?.codeVal)}>{element?.codeKey || '--'}</div>
          </div>
        )
      }
    })
    return val
  }

  const getCoinView = coin => {
    if (!symbolInfo) return coin
    const findCoin = symbolInfo.find(i => i.coinName === coin)
    if (findCoin?.coinName) {
      return <LazyImage className="coin-img" src={findCoin?.webLogo || ''} width={16} height={16} />
    }
    const img = get(currencyImg, [coin], '')
    if (img) {
      return (
        <div className="img">
          <img src={`${oss_area_code_image_domain_address}${img}.png`} alt="" />
        </div>
      )
    }
    return coin
  }

  const renderSkeleton = (val, loading) => {
    return (
      <Skeleton
        text={{
          rows: 1,
        }}
        loading={loading}
      >
        {val}
      </Skeleton>
    )
  }

  const dataOwn = gameLoading => {
    return [
      {
        title: t`features_entertainment_area_my_rebate_table_data_y6hk21j06l`,
        dataIndex: 'gameName',
        ellipsis: true,
        width: '16%',
        className: 'highlight',
        render: (_, row) => {
          return renderSkeleton(row?.gameName ? row?.gameName : '--', gameLoading)
        },
      },
      {
        title: t`order.columns.date`,
        dataIndex: 'createdByTime',
        align: 'right',
        width: '16%',
        ellipsis: true,
        render: (_, row) => {
          return renderSkeleton(row?.createdByTime ? formatDate(row?.createdByTime) : '--', gameLoading)
        },
      },
      {
        title: t`features_home_component_ranking_list_table_index_gh8akta889`,
        dataIndex: 'realAmount',
        align: 'right',
        render: (_, row) => {
          return renderSkeleton(
            row?.realAmount ? (
              <div className={styles['tabel-data']}>
                <div className="coin">
                  {formatCurrency(row?.realAmount)}
                  {getCoinView(row?.coin)}
                </div>
              </div>
            ) : (
              0
            ),
            gameLoading
          )
        },
      },
      {
        title: t`features_home_component_ranking_list_table_index_llknh4cgos`,
        dataIndex: 'odds',
        align: 'right',
        width: '13%',
        render: (_, row) => {
          return renderSkeleton(row?.odds ? `${row?.odds}x` : 0, gameLoading)
        },
      },
      {
        title: t`features_ternary_option_historical_component_historical_table_index_oop5lshpfi`,
        dataIndex: 'profitLoss',
        align: 'right',
        render: (_, row) => {
          return (
            <Skeleton
              text={{
                rows: 1,
              }}
              loading={gameLoading}
            >
              <div className={styles['tabel-data']}>
                <div className={profitLossNegative(row?.profitLoss)}>
                  {row?.profitLoss ? `${formatCurrency(row?.profitLoss)}` : '0'}
                  {getCoinView(row?.coin)}
                </div>
              </div>
            </Skeleton>
          )
        },
      },
    ] as TableColumnProps[]
  }

  /** 所有投注 */
  const columnsAll: TableColumnProps[] = [
    {
      title: t`features_entertainment_area_my_rebate_table_data_y6hk21j06l`,
      dataIndex: 'name',
      ellipsis: true,
      width: '17%',
      className: 'highlight',
    },
    {
      title: t`features_home_component_ranking_list_table_index_qeq20_9gi8`,
      dataIndex: 'player',
      align: 'right',
      width: '13%',
      ellipsis: true,
      className: 'highlight',
    },
    {
      title: t`order.columns.date`,
      dataIndex: 'time',
      align: 'right',
      width: '13%',
    },
    {
      title: t`features_home_component_ranking_list_table_index_gh8akta889`,
      dataIndex: 'betAmount',
      align: 'right',
      render: (_, row) => {
        return (
          <div className={styles['tabel-data']}>
            <div className="coin">
              {`${row?.betAmount}`} {getCoinView(DEFAULT_COIN)}
            </div>
          </div>
        )
      },
    },
    {
      title: t`features_home_component_ranking_list_table_index_llknh4cgos`,
      dataIndex: 'multiplier',
      align: 'right',
      width: '13%',
    },
    {
      title: t`features_ternary_option_historical_component_historical_table_index_oop5lshpfi`,
      dataIndex: 'paymentAmount',
      align: 'right',
      render: (_, row) => {
        return (
          <div className={styles['tabel-data']}>
            <div className={profitLossFn(Number(row?.paymentAmount || 0), personalCenterSettings.colors)}>
              {`+${row?.paymentAmount}`} {getCoinView(DEFAULT_COIN)}
            </div>
          </div>
        )
      },
    },
  ]
  /** 风云榜 */
  const columnsBillboard: TableColumnProps[] = [
    {
      title: t`features_home_component_ranking_list_table_index_u73_cqzu45`,
      dataIndex: 'ranking',
      width: '15%',
      render: (_, row) => {
        if (row.ranking <= 3) {
          return (
            <Image
              className="rank-image"
              preview={false}
              src={`${oss_ra_image_domain_address}icon_ranking_${row.ranking}.png`}
            />
          )
        } else {
          return `${row.ranking}th`
        }
      },
    },
    {
      title: t`features_home_component_ranking_list_table_index_qeq20_9gi8`,
      dataIndex: 'player',
      align: 'right',
      ellipsis: true,
      width: '15%',
      className: 'highlight',
    },
    {
      title: t`features_home_component_ranking_list_table_index_l0sylmetkx`,
      dataIndex: 'totalBet',
      align: 'right',
      width: '32%',
      render: (_, row) => {
        return (
          <div className={styles['tabel-data']}>
            <div className="coin">
              {`${row?.totalBet}`} {getCoinView(DEFAULT_COIN)}
            </div>
          </div>
        )
      },
    },
    {
      title: t`features_home_component_ranking_list_table_index_9i7fcirnqe`,
      dataIndex: 'bonus',
      align: 'right',
      width: '32%',
      render: (_, row) => {
        return (
          <div className={styles['tabel-data']}>
            <div className="coin">
              {`${row?.bonus}`} {getCoinView(DEFAULT_COIN)}
            </div>
          </div>
        )
      },
    },
  ]

  const ownList = { id: RankingListTableTypeEnum.own, name: t`features_home_my_bet_index_avwmsadjdl` }
  const allList = {
    id: RankingListTableTypeEnum.all,
    name: t`features_home_component_ranking_list_table_index_q2t7unlgki`,
  }
  const billboardList = {
    id: RankingListTableTypeEnum.billboard,
    name: t`features_home_component_ranking_list_table_index_mhm5a2mgzp`,
  }
  const competitionList = {
    id: RankingListTableTypeEnum.competition,
    name: t`features_home_component_ranking_list_table_index_ydzivz0sfi`,
  }

  useEffect(() => {
    if (localeInfo?.home?.rankingTable && localeInfo?.home?.rankingTable?.length > 0) {
      let list = localeInfo?.home?.rankingTable.filter(res => {
        res.name = getRankingListTableTypeName(res?.id)
        return !res?.loginLimit || hiddenOwn || isLogin
      })
      setTabsList(list)
      return
    }
    // 动态设置 tabs
    hiddenOwn || !isLogin
      ? setTabsList([allList, billboardList, competitionList])
      : setTabsList([ownList, allList, billboardList, competitionList])
  }, [hiddenOwn, isLogin, localeInfo?.home?.rankingTable])

  // const tabsData =
  // hiddenOwn || !isLogin
  //   ? [allList, billboardList, competitionList]
  //   : [ownList, allList, billboardList, competitionList]

  // 生成随机游戏名字
  const generateRandomGameName = () => {
    const firstNames = [
      t`features_home_component_ranking_list_table_index_gq2hcsm8ym`,
      t`features_home_component_ranking_list_table_index_gmns1it3uz`,
      t`features_home_component_ranking_list_table_index_9klfrqvxkj`,
      t`features_home_component_ranking_list_table_index_pc7wvvzpct`,
      t`features_home_component_ranking_list_table_index_28cur_kf_v`,
      t`features_home_component_ranking_list_table_index_gllcwlr1bx`,
      t`features_home_component_ranking_list_table_index_69mqivoewn`,
      t`features_home_component_ranking_list_table_index_mtb8onulaj`,
      t`features_home_component_ranking_list_table_index_sdz0kxpgoc`,
    ]
    return firstNames[Math.floor(Math.random() * firstNames.length)]
  }

  // 生成随机英文名字
  const generateRandomName = () => {
    const firstNames = ['Alice', 'Bob', 'Charlie', 'David', 'Emma', 'Frank', 'Grace', 'Henry', 'Ivy', 'Jack']
    const lastNames = ['Smith', 'Johnson', 'Williams', 'Jones', 'Brown', 'Davis', 'Miller', 'Wilson', 'Moore', 'Taylor']

    const randomFirstName = firstNames[Math.floor(Math.random() * firstNames.length)]
    const randomLastName = lastNames[Math.floor(Math.random() * lastNames.length)]

    return `${randomFirstName} ${randomLastName}`
  }

  // 生成随机金额
  const generateRandomAmount = (min, max) => {
    return (Math.random() * (max - min) + min).toFixed(2)
  }

  /**
   * 我的投注默认数据请求
   * */
  const { run: queryGame, loading: gameLoading } = useRequest(
    async () => {
      setMyBetData(Array(skeletonNum).fill({}))
      const res = await getRaHallGameRecord({
        ...fromDefault,
        startTime: undefined,
        endTime: undefined,
      })
      if (res.isOk && res.data) {
        setMyBetData(res?.data?.list || [])
      }
    },
    { manual: true }
  )

  const onTabsChange = v => v && setTableId(v)

  /**
   * 数据字典
   */
  const { run: dataDictionary, loading } = useRequest(
    async () => {
      const res = await getCodeDetailList({ codeVal: 'ra_center_sett_state', lanType: locale })
      if (res.isOk && res.data) {
        setDataList(res.data)
        queryGame()
        setCurrentColumns(TableData(res.data))
      }
    },
    { manual: true }
  )

  /** 处理表格数据 */
  const handleTableData = () => {
    const newTableArray = new Array(10).fill('1')
    const currentData = [] as any
    switch (tableId) {
      case RankingListTableTypeEnum.all:
        newTableArray.forEach(() => {
          const currnetMoney = `${generateRandomAmount(10, 100)}`
          currentData.push({
            name: generateRandomGameName(),
            player: generateRandomName(),
            time: dayjs().format('HH:mm'),
            betAmount: `${currnetMoney}`,
            multiplier: '1 x',
            paymentAmount: currnetMoney,
          })
        })
        setCurrentColumns(columnsAll)
        break
      case RankingListTableTypeEnum.billboard:
        newTableArray.forEach(() => {
          const currnetMoney = `${generateRandomAmount(10, 100)}`
          currentData.push({
            name: generateRandomGameName(),
            player: generateRandomName(),
            time: dayjs().format('HH:mm'),
            betAmount: `${currnetMoney}`,
            multiplier: '1 x',
            paymentAmount: currnetMoney,
          })
        })
        setCurrentColumns(columnsAll)
        break
      case RankingListTableTypeEnum.competition:
        newTableArray.forEach((_, index) => {
          currentData.push({
            ranking: index + 1,
            player: generateRandomName(),
            totalBet: `${generateRandomAmount(50000, 100000)}`,
            bonus: `${generateRandomAmount(100000, 1000000)}`,
          })
        })
        setCurrentColumns(columnsBillboard)
        break
      case RankingListTableTypeEnum.own:
        queryGame()
        setMyBetColumns(dataOwn)
        break
      default:
        break
    }
    setData(currentData)
  }

  useEffect(() => {
    if (tableId) {
      handleTableData()
    }
  }, [tableId, symbolInfo])

  useEffect(() => {
    const idList = localeInfo?.home?.rankingTable?.map(res => res?.id) || []
    const val =
      idList?.length > 0 && !idList?.includes(RankingListTableTypeEnum.billboard)
        ? idList[idList.length - 1]
        : RankingListTableTypeEnum.billboard

    setTableId(val)
  }, [localeInfo?.home?.rankingTable])

  if (isEmpty(symbolInfo)) {
    return (
      <div className={styles.loading}>
        <Spin />
      </div>
    )
  }

  return (
    <div className={classNames(styles['rangking-list-table-wrap'], className)}>
      <RecordTabs value={tableId} tabs={tabsList} onChange={onTabsChange} />
      {tableId === RankingListTableTypeEnum.own ? (
        isEmpty(currencyImg) ? (
          <div className={styles.loading}>
            <Spin />
          </div>
        ) : (
          <CommonTable columns={dataOwn(gameLoading)} data={myBetData} />
        )
      ) : (
        <CommonTable columns={currentColumns} data={data} />
      )}
    </div>
  )
}
